import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

//import { Button, Select } from 'element-ui'; //按需引入
import ElementUI from 'element-ui';//全局进入
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'


// Vue.use(Button)
// Vue.use(Select)
Vue.use(ElementUI) //npm添加全局组件
//Vue.use(router)

Vue.use(VueAxios,axios);//注意需要先注册VueAxios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
