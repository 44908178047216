<template>
  <div ref="canvasContainer"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      controls: null
    };
  },
  mounted() {
    this.initScene();
    window.addEventListener('resize', this.handleWindowResize);
  },
  methods: {
    initScene() {
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(this.renderer.domElement);

      const geometry = new THREE.BoxGeometry(2, 2, 2);
      const materialArray = [];
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(require('@/views/images/weiruan/b7.jpg'));

      // 设置立方体的每个面的纹理
      for (let i = 0; i < 6; i++) {
        materialArray.push(new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide }));
      }

      const cubeMaterial = materialArray;
      const cube = new THREE.Mesh(geometry, cubeMaterial);
      this.scene.add(cube);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;

      this.camera.position.z = 5;

      this.animate();
    },
    handleWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    }
  },
  beforeDestroy() {
    if (this.renderer) {
      this.renderer.dispose();
    }
    window.removeEventListener('resize', this.handleWindowResize);
  }
};
</script>
