<template>
  <div>
    <!-- 导航栏 -->
    <el-menu class="menu" mode="horizontal" :default-active="activeIndex" @select="handleSelect">
        <el-menu-item index="home">Home</el-menu-item>
            <el-submenu index="articles">
                <template slot="title">Articles</template>
                <el-menu-item index="vue">Vue</el-menu-item>
                <el-menu-item index="element-ui">Element-UI</el-menu-item>
                <el-menu-item index="javascript">JavaScript</el-menu-item>
                <el-menu-item index="css">CSS</el-menu-item>
            </el-submenu>
        <el-menu-item index="about">About</el-menu-item>
        <!-- 搜索 -->
        <div style="float: right; margin-right: 80px; margin-top: 7px;">
          <el-input v-model="searchText" placeholder="Enter keywords" style="width: 200px;"></el-input>
          <el-button type="primary" @click="queryArticlesF">Search</el-button>
        </div>
    </el-menu>

      <!-- 巨幕区 -->
    <div class="jumbotron">
      <h1 class="display-4">三人行，必有我师</h1>
      <p class="lead">所谓的门槛，能力够了就是门，能力不够那就是槛。</p>
      <hr class="my-4">
      <p>技术学习，技术共享，技术交流。在本站，你可以学到JAVA、PYTHON、PHP、C/C++、GO等主流编程语言，让你的学习没有门槛。</p>
      <el-button type="primary" size="medium" @click="startLearning">ChatGPT开始学习</el-button>
    </div>

    <!-- 内容区 -->
    <div class="content">
      <div class="tags">
        <!-- <el-tag v-for="tag in tags" :key="tag.id" :type="getRandomColor()" class="tag">{{ tag.name }}</el-tag> -->
        <el-tag v-for="tag in tags" :key="tag.id" :type="tagColorMap[tag.id]" class="tag">{{ tag.tagName }}</el-tag>
      </div>

      <div class="articles">
        <el-card @click.native="articleJump(article)" v-for="article in articles" :key="article.id" class="article" shadow="hover">
          <div slot="header" class="article-header">{{ article.title }}</div>
          <div class="article-description">{{ truncatedContent(article.content, article.id) }}</div>
          <div class="article-info">{{ article.userName }} • {{ article.updateTime }}</div>
        </el-card>

        <el-pagination
          v-if="total > 5"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15]"
          :page-size= "pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 尾部 -->
    <div class="footer">PLY 2014 <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action">京ICP备2024045420号</a></div>
  </div>
</template>
  
<script>
import {queryArticles,queryTag} from '@/api/article-api';
export default {
  data() {
    return {
      searchText: '',
      searchCriteria: [],
      activeIndex: 'home',
      tags: [],
      articles: [],
      pageSize: 5,
      currentPage: 1,
      total:0,
    };
  },
  computed: {
    // 计算属性，生成标签颜色映射表
    tagColorMap() {
      const colors = ['primary', 'success', 'warning', 'danger'];
      const tagColorMap = {};
      this.tags.forEach(tag => {
        tagColorMap[tag.id] = colors[Math.floor(Math.random() * colors.length)];
      });
      return tagColorMap;
    },
    truncatedContent() {
      const maxLength = 100; // 设置最大显示字数
      return (content, index) => {
        if (content.length > maxLength) {
          return content.slice(0, maxLength) + '...';
        } else {
          return content;
        }
      };
    },
  },
  mounted() {
    this.queryArticlesF();
    this.queryTagF();
  },
  methods: {
    startLearning() {
      window.open('http://chat.zkkcc.cn:3002/', '_blank'); // 修改成你要打开的页面
    },
    handleSizeChange(val) {
      this.pageSize=val;
      this.queryArticlesF();
    },
    handleCurrentChange(val) {
      this.currentPage=val;
      this.queryArticlesF();
    },
    queryArticlesF() {
      let param ={};
      param.title=this.searchText;
      param.pageNum = this.currentPage;
      param.pageSize = this.pageSize;
      queryArticles(param).then(res=>{
        this.articles=res.data.data.pageData;
        this.total=res.data.data.total;
      });
    },
    getRandomColor() {
      const colors = ['primary', 'success', 'warning', 'danger'];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    handleSelect(index) {
      this.activeIndex = index;
    },
    searchArticles() {

    },
    queryTagF(){
      queryTag().then(res=>{
        this.tags=res.data.data;
      })
    },
    articleJump(data){
      this.$router.push({ path: '/s3', query: { articleData: data} });
    }
  },
};
</script>
  
<style scoped>
.selected-tag {
  background-color: #f1f1f1;
  color: #333;
}

.selected-criteria-item {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 6px;
  background-color: #f1f1f1;
  color: #333;
  border-radius: 15px;
}
.menu {
  margin-bottom: 20px;
  height: 50px; 
}
.jumbotron {
  background-color: #f8f9fa;
  padding: 2rem;
  text-align: center;
}

.jumbotron h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.jumbotron hr {
  border-color: #343a40;
  margin: 1rem auto;
  width: 40%;
}

.jumbotron p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 200px - 60px);
}

.tags {
  width: 20%; /* Adjust the width of the tags section */
}

.tag {
  margin: 5px;
  border-radius: 20px; /* Make tags elliptical */
}

.articles {
  width: 75%; /* Adjust the width of the articles section */
}

.article {
  margin: 10px 0;
}

.article-header {
  font-size: 18px;
}

.article-description {
  color: #666;
}

.article-info {
  color: #999;
}

.footer {
  text-align: center;
  padding: 10px 0;
}
</style>
  