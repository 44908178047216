<template>
  <div>
    <!-- 巨幕 -->
    <div class="jumbotron">
      <h1 class="display-4">三人行，必有我师</h1>
      <p class="lead">所谓的门槛，能力够了就是门，能力不够那就是槛。</p>
      <hr class="my-4">
      <p>技术学习，技术共享，技术交流。在本站，你可以学到JAVA、PYTHON、PHP、C/C++、GO等主流编程语言，让你的学习没有门槛。</p>
      <el-button type="primary" size="medium" @click="startLearning">ChatGPT开始学习</el-button>
    </div>

    <!-- 内容 -->
    <div>
      
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    startLearning() {
      window.open('http://chat.zkkcc.cn:3002/', '_blank'); // 修改成你要打开的页面
    }
  }
}
</script>

<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 2rem;
  text-align: center;
}

.jumbotron h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.jumbotron hr {
  border-color: #343a40;
  margin: 1rem auto;
  width: 40%;
}

.jumbotron p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

</style>
