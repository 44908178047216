<template>
    <div>
        <footer>
            <p>网站备案号：ICP备12345678号</p>
            <p>主办单位：XXX公司</p>
            <p>地址：XX省XX市XX区XX街道</p>
            <p>公安机关备案号：1234567890</p>
        </footer>
    </div>
</template>

<script>
  export default {
    data() {
        return {

        }
    }
  }
</script>

<style scoped>
</style>
