<template>
    <div class="markdown-editor">
      <div class="toolbar">
        <el-button-group>
          <el-tooltip content="加粗" placement="bottom">
            <el-button type="text" icon="el-icon-bold" @click="insertText('**', '**')"></el-button>
          </el-tooltip>
          <el-tooltip content="斜体" placement="bottom">
            <el-button type="text" icon="el-icon-italic" @click="insertText('_', '_')"></el-button>
          </el-tooltip>
          <el-tooltip content="链接" placement="bottom">
            <el-button type="text" icon="el-icon-link" @click="insertText('[', '](url)')"></el-button>
          </el-tooltip>
          <el-tooltip content="引用" placement="bottom">
            <el-button type="text" icon="el-icon-reading" @click="insertText('> ', '')"></el-button>
          </el-tooltip>
        </el-button-group>
      </div>
      <div class="content">
        <div class="editor">
          <el-input
            v-model="markdown"
            type="textarea"
            :rows="10"
            placeholder="在这里输入 Markdown"
            resize="none"
          ></el-input>
        </div>
        <div class="preview" v-html="compiledMarkdown"></div>
      </div>
    </div>
  </template>
  
  <script>
  import marked from 'marked';
  
  export default {
    data() {
      return {
        markdown: '',
        compiledMarkdown: ''
      };
    },
    watch: {
      markdown(newMarkdown) {
        this.compiledMarkdown = marked(newMarkdown);
      }
    },
    methods: {
      insertText(startTag, endTag) {
        const textarea = this.$refs.markdownInput.$refs.textarea;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const value = this.markdown;
  
        const newValue =
          value.substring(0, startPos) +
          startTag +
          value.substring(startPos, endPos) +
          endTag +
          value.substring(endPos);
  
        this.markdown = newValue;
        this.$nextTick(() => {
          textarea.focus();
          textarea.setSelectionRange(startPos + startTag.length, endPos + startTag.length);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .markdown-editor {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  
  .toolbar {
    padding: 10px;
  }
  
  .content {
    display: flex;
    flex: 1;
  }
  
  .editor {
    flex: 1;
    padding: 10px;
  }
  
  .preview {
    flex: 1;
    padding: 10px;
    border-left: 1px solid #ebeef5;
  }
  
  .preview img {
    max-width: 100%;
  }
  </style>
  