import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Main from '../views/Main.vue'
import Mall from '../views/Mall.vue'
import PageOne from '../views/PageOne.vue'
import PageTwo from '../views/PageTwo.vue'

import views from '@/views/'
Vue.use(VueRouter)


const whiteList = ['/login']; // 白名单，包含不需要进行登录检查的路由路径

const routes = [
  //登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/s1',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest1.vue')
  },
  {
    path: '/s2',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest2.vue')
  },
  {
    path: '/s3',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest3.vue')
  },
  {
    path: '/s4',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest4.vue')
  },
  {
    path: '/s5',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest5.vue')
  },
  {
    path: '/s6',
    name: 'MdTest1',
    component: () => import('../views/test/MdTest6.vue')
  },
  //主路由
  {
      path: '/home',
      component: Main,
      redirect: '/home',//重定向
      children: [
          //子路由
          { path: '/home', component: Home },//首页
          { path: '/mall', component: Mall },//商品管理
          { path: '/user', component: User },//用户管理
          { path: '/page1', component: PageOne },//页面1
          { path: '/page2', component: PageTwo },//页面2
          { path: '/c1', component: views.MdTest1 },//测试1
          { path: '/c2', component: views.MdTest2 },//测试2
          { path: '/c3', component: views.MdTest3 },//测试3
      ]
  }
]

// 3. 创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
    mode: 'hash',
    routes // (缩写) 相当于 routes: routes
  })

router.beforeEach((to, from, next) => {
  const hasToken = sessionStorage.getItem("Authorization");
  if (hasToken) {
    // 如果已经登录，则直接进入目标路由
    next();
  } else {
    // 如果没有登录，则判断目标路由是否在白名单中
    if (whiteList.indexOf(to.path) !== -1) {
      // 如果在白名单中，则可以直接进入目标路由
      next();
    } else {
      // 否则重定向到登录页面
      next('/login');
    }
  }
});
  
export default router








