import { mapMutations } from "vuex";

export default {
    state: {
        isCollapse: false //用于控制菜单的展开or展示
    },
    mutations: {
        //修改菜单展示收起的方法
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        }
    }
}