import request from '@/utils/request'

//1.文章查询
export function queryArticles(data) {
    return request({
        url: '/user/article/queryArticles',
        method: 'post',
        data,
    })
}

//2.标签查询
export function queryTag(data) {
    return request({
        url: '/user/article/queryTag',
        method: 'post',
        data,
    })
}

//3.修改文章
export function updateArticles(data) {
    return request({
        url: '/user/article/updateArticles',
        method: 'post',
        data,
    })
}