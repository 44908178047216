import axios from 'axios'
import Vue from 'vue';

const http = axios.create({
  //通用请求的地址前缀
  baseURL: 'http://www.zkkcc.cn:8081/api', //182.92.131.53
  timeout: 60000,
  withCredentials: true, // 是否携带跨域请求的凭据（如 cookie）
});

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = sessionStorage.getItem("Authorization"); // 假设 token 存储在 sessionStorage 中
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
      // 对于服务器返回的错误进行处理
      console.error('Server Error:', error.response.data);
      // 可以根据具体情况显示友好的错误提示给用户
      Vue.prototype.$notify({
          type: 'error',
          message: '服务器错误，请稍后重试',
      });
  } else if (error.request) {
      // 网络请求已经发出，但没有收到响应
      console.error('Request Error:', error.request);
      Vue.prototype.$notify({
          type: 'error',
          message: '网络请求失败，请检查网络连接',
      });
  } else {
      // 其他错误情况下的处理逻辑，包括 "Network Error"
      console.error('Error:', error.message);
      Vue.prototype.$notify({
          type: 'error',
          message: '网络错误，请稍后重试',
      });
  }
  return Promise.reject(error);
});


export default http