<template>
  <div class="blog-editor">
    <!-- 编辑区域 -->
    <textarea v-model="content" @input="renderPreview"></textarea>

    <!-- 展示区域 -->
    <!-- <div class="blog-preview" v-html="preview"></div> -->
    <div class="blog-preview">
      <vue-markdown :source="markdownContent" />
    </div>

    <div class="blog-right">
      <el-button type="primary" @click="updateArticlesF">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>

  </div>
</template>
  
<script>
import {queryArticles,updateArticles} from '@/api/article-api';

import marked from 'marked'
import VueMarkdown from 'vue-markdown'
export default {
  name: 'BlogEditor',
  components: { VueMarkdown },
  data() {
    return {
      content: '',
      preview: '',
      markdownContent: '',
      articleId: '',/*文章ID*/
      articleData:{

      },
    }
  },
  created() {
    this.content = this.$route.query.articleData.content;
    this.markdownContent = this.$route.query.articleData.content;
    this.articleData=this.$route.query.articleData;
    console.log("articleData",this.articleData);
  },
  methods: {
    publicNotice(flag, message) {//公共通知方法
      if (!flag) {
        this.$notify.error({
          title: '错误',
          message: message
        });
      } else {
        this.$notify({
          title: '成功',
          message: message,
          type: 'success'
        });
      }
    },
    renderPreview() {
      this.preview = marked(this.content)
      this.markdownContent = this.content
    },
    updateArticlesF() {
      let param ={};
      param.id = this.articleData.id;
      param.title = this.articleData.title;
      param.content = this.content;
      updateArticles(param).then(res=>{
         let flag = res.data.flag;
         let message = res.data.message;
        if (flag) {
          this.queryArticlesF();
        }
        this.publicNotice(flag,message);
      });
    },
    queryArticlesF() {
      let param ={};
      param.id = this.articleData.id;
      queryArticles(param).then(res=>{
        console.log("data",res.data.data.pageData[0])
        this.articleData=res.data.data.pageData[0];
        this.content=this.articleData.content;
        this.preview=this.articleData.content;
      });
    }
  },
}
</script>
  
<style scoped>
.blog-right {
  margin-left: 10px;
}

.blog-editor {
  display: flex;
  flex-direction: row;
  margin-left: 200px;
}

.blog-editor textarea {
  width: 40%;
  height: 870px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-right: none;
  resize: none;
  box-sizing: border-box;
  background-color: #4d4d4d09;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.blog-preview {
  width: 40%;
  height: 870px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #ccc;
  overflow-y: auto;
  box-sizing: border-box;
}

/* 针对Markdown渲染结果进行样式调整 */
.blog-preview h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
}

.blog-preview h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px;
}

.blog-preview h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.blog-preview p {
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-preview code {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 2px 5px;
  font-family: Consolas, "Courier New", monospace;
  font-size: 14px;
}

.blog-preview pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 10px;
  overflow-x: auto;
}
</style>
  