<template>
  <div class="blog-list">
    <!-- 巨幕部分 -->
    <el-header class="jumbotron" style="background-color: #333; height: 200px;">
      <!-- 预留位置，覆盖颜色 -->
    </el-header>

    <el-container>
      <!-- 左侧展示标签部分 -->
      <el-aside width="30%" class="tag-list">
        <div class="tag-item" v-for="(item, index) in tagList" :key="index">
          <div class="tag-cylinder" :style="{ background: item.color, width: item.width }"></div>
        </div>
      </el-aside>

      <!-- 右侧展示文章部分 -->
      <el-main class="article-list">
        <div class="article-card" v-for="(post, index) in limitedPosts" :key="index">
          <h3>{{ post.title }}</h3>
          <p>{{ post.description }}</p>
          <div class="author-time">
            <span class="author">作者：{{ post.author }}</span>
            <span class="time">时间：{{ post.time }}</span>
          </div>
        </div>
      </el-main>
    </el-container>

    <!-- 底部备案信息 -->
    <el-footer class="footer">
      <div class="icp-info">ICP备案号：123456789</div>
    </el-footer>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        tagList: [
          { color: '#FF6B6B', width: '80px' },
          { color: '#4ECDC4', width: '100px' },
          { color: '#FFD166', width: '120px' },
          { color: '#AC92EB', width: '90px' },
          { color: '#6AB04C', width: '110px' }
        ],
        posts: [
          {
            title: '文章标题1',
            description: '文章描述1',
            author: '张三',
            time: '2024-01-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          {
            title: '文章标题2',
            description: '文章描述2',
            author: '李四',
            time: '2024-02-01'
          },
          // 更多文章...
        ],
        maxPostCount: 10 // 控制文章数量
      };
    },
    computed: {
      limitedPosts() {
        return this.posts.slice(0, this.maxPostCount);
      }
    }
  };
  </script>
  
  <style scoped>
  .blog-list {
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
  }
  
  .jumbotron {
    /* 已完成的巨幕样式 */
  }
  
  .tag-list {
    background-color: #FFFFFF;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .tag-item {
    margin-bottom: 20px;
  }
  
  .tag-cylinder {
    height: 60px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  
  .article-list {
    padding: 20px;
  }
  
  .article-card {
    background-color: #FFFFFF;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
  
  .author-time {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .footer {
    background-color: #333;
    padding: 20px;
    text-align: center;
    color: #FFF;
  }
  
  .icp-info {
    margin-top: 10px;
  }
  </style>
  