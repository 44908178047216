const files = require.context('../views/test', true, /\.vue$/)
const modules = {}

files.keys().forEach(key => {
  if (key === './index.js') return
  const name = key.match(/\w+/)[0]
  modules[name] = files(key).default || files(key)
  console.log("---------->"+name)
})

export default modules
