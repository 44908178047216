<template>
    <div>
        <el-container>
            <el-aside width="auto">
              <common-aside/><!-- 小驼峰规范 -->
            </el-aside>
            <el-container>
                <el-header>
                  <common-header/>
                </el-header>
                <el-main>
                    <!-- 路由出口 -->
                    <!-- 路由匹配到的组件将渲染在这里 -->
                    <router-view></router-view>
                </el-main>
                <!-- <el-footer>
                  <common-footer/>
                </el-footer> -->
            </el-container>
        </el-container>
    </div>
</template>
<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
import CommonFooter from '../components/CommonFooter.vue'
    export default {
        data() {
            return {}
        },
        components: {
          'common-aside': CommonAside,
          'common-header': CommonHeader,
          'common-footer': CommonFooter,
        },
    }
</script>

<style>
  .el-header {
    padding: 0;
  }
</style>