<template>
    <div>
        <h1>Page1</h1>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            
        }
    }
</script>