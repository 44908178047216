<template>
  <div>
    <el-dialog
      title="发布"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="blog-create">
          <el-form ref="form" :model="formData" label-position="top">
              <el-form-item label="标题">
              <el-input v-model="formData.title"></el-input>
              </el-form-item>
              <el-form-item label="分类">
              <el-select v-model="formData.category" placeholder="请选择">
                  <el-option label="前端" value="frontEnd"></el-option>
                  <el-option label="后端" value="backEnd"></el-option>
                  <el-option label="运维" value="operation"></el-option>
              </el-select>
              </el-form-item>
              <el-form-item label="标签">
              <el-input v-model="formData.tag"></el-input>
              </el-form-item>
              <el-form-item label="内容">
              <el-input type="textarea" v-model="formData.content"></el-input>
              </el-form-item>
              <el-form-item>
              <el-button type="primary" @click="submitForm">提交</el-button>
              </el-form-item>
          </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    
    <div>
      <div>
        <el-card v-for="post in posts" :key="post.id" class="blog-post">
          <div slot="header" class="blog-post-header">
            {{ post.title }}
          </div>
          <div class="blog-post-content">
            {{ post.content }}
          </div>
          <div class="blog-post-meta">
            <span>{{ post.author }}</span>
            <span>{{ post.date }}</span>
          </div>
        </el-card>
      </div>
    </div>


  </div>
</template>
  
<script>
import {queryArticles} from '@/api/article-api';
export default {
  data() {
    return {
      formData: {
        title: '',
        category: '',
        tag: '',
        content: ''
      },
      dialogVisible: false,
      pageCont: {
        pageNum: 0,
        pageSize: 10,
      },
      posts: [ // 模拟文章数据
        { id: 1, title: '文章标题1', content: '文章内容1', author: '作者A', date: '2024-02-20' },
        { id: 2, title: '文章标题2', content: '文章内容2', author: '作者B', date: '2024-02-21' },
        // 更多文章数据...
      ]
    };
  },
  mounted() {
    let param=this.pageCont;
    queryArticles(param).then(res=>{
      
    })
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 提交表单数据
          console.log(this.formData);
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
  }
};
</script>
  
<style scoped>
.blog-create {
  max-width: 600px;
  margin: 0 auto;
}

.blog-post {
  margin-bottom: 20px;
}
.blog-post-header {
  background-color: #007BFF; /* 标题颜色 */
  color: #FFFFFF; /* 标题文字颜色 */
}
.blog-post-content {
  color: #333333; /* 文章内容文字颜色 */
}
.blog-post-meta {
  color: #999999; /* 元数据文字颜色 */
}
</style>
  